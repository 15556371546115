import React, { useEffect } from "react";
import AboutMainHero from "./AboutMainHero/AboutMainHero";
import MagicMakerContent from "./MagicMakerContent/MagicMakerContent";
import PublicisHero from "./PublicisHero/PublicisHero";
import OfferingSolutions from "./OfferingSolutions/OfferingSolutions";
import MakingImpossible from "./MakingImpossible/MakingImpossible";
import NearOffshoreGrid from "./NearOffshoreGrid/NearOffshoreGrid";
import RegionalGlobalGrid from "./RegionalGlobalGrid/RegionalGlobalGrid";

const AboutUs = () => {

  useEffect(() => {
    document.title = 'Careers - About Us';
  }, []);

  return(
    <div className="about">
      <div className="about__wrapper">
        <AboutMainHero />
        <MagicMakerContent />
        <PublicisHero />
        <OfferingSolutions />
        <MakingImpossible />
        <RegionalGlobalGrid />
        <NearOffshoreGrid />
      </div>
    </div>
  )
}

export default AboutUs;
