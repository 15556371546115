import React, { Component } from "react";
import * as wbApi from "./../../API/CareersApi";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  getCountries as getCountriePhoneAPI,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import getCountries from "../../getCountries";
// import ReCAPTCHA from "react-google-recaptcha";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);
const validInputRegex =
  /^([a-z-À-ÿ\u00f1\u00d1])+\s*([a-z-À-ÿ\u00f1\u00d1])+\s*([a-z-À-ÿ\u00f1\u00d1])*$/i;

class ContactForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    flagHandler: "",
    message: "",
    country: "",
    countryCode: "",
    countryCodeNumber: "",
    firstNameErr: "",
    lastNameErr: "",
    emailErr: "",
    countryErr: "",
    messageErr: "",
    phoneErr: "",
    recaptchaError: "",
    showLoading: false,
  };

  componentDidMount() {
    document
      .querySelector("#contact_us_country_code")
      .setAttribute("readonly", true);

    getCountries()
      .then((response) => response.json())
      .then((data) => {
        //Get the country code
        const selectedCountryCode = data.country_code;
        if (
          selectedCountryCode !== "" &&
          typeof selectedCountryCode !== "undefined"
        ) {
          this.setState({
            countryCode: selectedCountryCode,
            country: data.country_name,
            countryCodeNumber: data.country_calling_code,
          });
        }
      });
  }

  handleChangeOption = (e) => {
    const { name, value } = e.target;
    const errState = {};

    switch (name) {
      case "firstName":
        errState.firstNameErr =
          value.length <= 1
            ? "Please enter your name."
            : "" || !value.match(validInputRegex)
            ? "Name must be letters only."
            : "";
        break;
      case "lastName":
        errState.lastNameErr =
          value.length <= 1
            ? "Please enter your last name."
            : "" || !value.match(validInputRegex)
            ? "Name must be letters only."
            : "";
        break;
      case "email":
        errState.emailErr =
          value.length <= 1
            ? "Please enter your email."
            : "" || validEmailRegex.test(value)
            ? ""
            : "Email address is not valid.";
        break;
      case "message":
        errState.messageErr =
          value.length <= 1 ? "Please provide a message." : "";
        break;
      default:
        break;
    }
    this.setState({
      ...errState,
      [e.target.name]: e.target.value,
    });
  };

  handleValidatePhone = () => {
    if (this.state.phone !== "" && typeof this.state.phone !== "undefined") {
      if (isValidPhoneNumber(this.state.countryCodeNumber + this.state.phone)) {
        this.setState({ phoneErr: "" });
      } else {
        this.setState({ phoneErr: "Invalid phone number" });
      }
    } else {
      this.setState({
        phoneErr: "Please enter a phone number",
      });
    }
  };

  handleChangeOptionPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  handleChangeOptionCountry = ({ target }) => {
    const countryErr = target.value === "" ? "Please select a country." : "";
    const codeNumber =
      target.value === "" || target.value === "ZZ"
        ? ""
        : "+" + getCountryCallingCode(target.value);

    this.setState({
      countryErr,
      countryCode: target.value,
      flagHandler: "",
      country: target.selectedOptions[0].text,
      countryCodeNumber: codeNumber,
    });
  };

  handleChangeOptionFlag = (value) => {
    let countryCode = document.querySelector(
      "#contact_us_country_code_selected_flag"
    ).value;
    let codeNumber =
      countryCode === "ZZ" ? "" : "+" + getCountryCallingCode(countryCode);

    this.setState({
      countryCodeNumber: codeNumber,
      flagHandler: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      let data = {
        name: this.state.firstName + " " + this.state.lastName,
        email: this.state.email,
        phone: this.state.countryCodeNumber + this.state.phone,
        message: this.state.message,
        country: this.state.country,
        countryCode: this.state.countryCode,
      };

      this.setState({
        showLoading: true,
      });

      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_SITE_KEY, {action: 'demo'})
            .then(function(token) {
              console.log('token:', token);
              wbApi.postContactUs("mail", data,token); 
                
            });
      });

      //aqui debo hacer el timeout del loading
      setTimeout(() => {
        this.setState({
          showLoading: false,
        });
      }, 3000);

      this.setState(() => ({
        firstName: "",
        lastName: "",
        country: "",
        countryCode: "",
        email: "",
        phone: "",
        message: "",
        firstNameErr: "",
        lastNameErr: "",
        emailErr: "",
        countryErr: "",
        messageErr: "",
        phoneErr: "",
        recaptchaError: "",
      }));
    } else {
      return false;
    }
  };

  //Form validation on submit
  validateForm = () => {
    let formIsValid = true;
    let _self = this;

    if (!this.state.firstName) {
      formIsValid = false;
      this.setState({ firstNameErr: "Please provide your name." });
    }

    if (typeof this.state.firstName !== "undefined") {
      if (!this.state.firstName.match(validInputRegex)) {
        formIsValid = false;
        this.setState({ firstNameErr: "Name must be letters only." });
      }
    }

    if (!this.state.lastName) {
      formIsValid = false;
      this.setState({ lastNameErr: "Please provide your last name." });
    }

    if (typeof this.state.lastName !== "undefined") {
      if (!this.state.lastName.match(validInputRegex)) {
        formIsValid = false;
        this.setState({ lastNameErr: "Last name must be letters only." });
      }
    }

    if (!this.state.email) {
      formIsValid = false;
      this.setState({ emailErr: "Please provide your email address." });
    }

    if (typeof this.state.email !== "undefined") {
      if (!validEmailRegex.test(this.state.email)) {
        formIsValid = false;
        this.setState({ emailErr: "Email address is not valid." });
      }
    }

    if (this.state.country === "" || typeof this.state.country === undefined) {
      formIsValid = false;
      this.setState({ countryErr: "Please select a country." });
    }

    if (!this.state.message) {
      formIsValid = false;
      this.setState({ messageErr: "Please provide a message." });
    }

    if (this.state.phone === "" || this.state.phone === "undefined") {
      formIsValid = false;
      this.setState({ phoneErr: "Please enter a phone number." });
    }

    if (!isValidPhoneNumber(this.state.countryCodeNumber + this.state.phone)) {
      formIsValid = false;
      this.setState({ phoneErr: "Please enter a valid phone number." });
    }

    if (
      this.state.firstNameErr === "" &&
      this.state.lastNameErr === "" &&
      this.state.emailErr === "" &&
      this.state.countryErr === "" &&
      this.state.messageErr === "" &&
      this.state.phoneErr === "" &&
      this.state.recaptchaError === ""
    ) {
      return formIsValid;
    }
  };

  render() {
    return (
      <div className="contact-form">
        <div className="contact-form__container">
          {this.state.showLoading ? (
            <div className="hot-positions__no-results">
              <p>Sending</p>
              <img
                src={require("../../../img/loading-positions.gif")}
                alt="Loading positions"
                className="hot-positions__loading-results-img"
              />
            </div>
          ) : null}
          <form onSubmit={this.handleSubmit} noValidate>
            <div className="contact-form__container-row">
              <div className="contact-form__container-row--input-container">
                <label htmlFor="firstName" className="label-form">
                  First Name
                </label>
                <input
                  name="firstName"
                  className="contact-form__container-row--input-text regular"
                  type="text"
                  pattern="[a-zA-ZÀ-úñÑ\s]+"
                  title="Letters only"
                  placeholder="First name"
                  value={this.state.firstName}
                  onChange={(e) => this.handleChangeOption(e)}
                  required
                />
                <span className="errorMsg">{this.state.firstNameErr}</span>
              </div>
              <div className="contact-form__container-row--input-container">
                <label htmlFor="lastName" className="label-form">
                  Last Name
                </label>
                <input
                  name="lastName"
                  className="contact-form__container-row--input-text regular"
                  type="text"
                  pattern="[a-zA-ZÀ-úñÑ\s]+"
                  title="Letters only"
                  placeholder="Last name"
                  required
                  value={this.state.lastName}
                  onChange={(e) => this.handleChangeOption(e)}
                />
                <span className="errorMsg">{this.state.lastNameErr}</span>
              </div>
            </div>
            <div className="contact-form__container-row">
              <div className="contact-form__container-row-country regular">
                <label htmlFor="country" className="label-form">
                  Country
                </label>
                <select
                  name="country"
                  className="country-selector contact-form__container-row--input-text"
                  value={this.state.countryCode}
                  onChange={(e) => this.handleChangeOptionCountry(e)}
                  required
                >
                  <option value="">Select a country</option>
                  {getCountriePhoneAPI().map((country) => (
                    <option key={country} value={country}>
                      {en[country]}
                    </option>
                  ))}
                </select>
                <span className="errorMsg bold">{this.state.countryErr}</span>
              </div>
              <div className="career-apply__container-row-mobile">
                <label htmlFor="mobile" className="label-form">
                  Mobile Number
                </label>
                <div className="contact-form__container-row-mobile">
                  <PhoneInput
                    className="phoneSelector contact-form__container-row--input-text input-flag regular"
                    name="country code"
                    placeholder={this.state.countryCodeNumber}
                    limitMaxLength={true}
                    defaultCountry={this.state.countryCode}
                    value={this.state.flagHandler}
                    onChange={(value) => this.handleChangeOptionFlag(value)}
                    countrySelectProps={{
                      id: "contact_us_country_code_selected_flag",
                    }}
                    numberInputProps={{ id: "contact_us_country_code" }}
                    required
                  />

                  <div className="input-number">
                    <input
                      className="phoneSelector contact-form__container-row--input-text input-number regular"
                      name="phone"
                      type="number"
                      placeholder="Mobile number"
                      value={this.state.phone}
                      onChange={(value) => this.handleChangeOptionPhone(value)}
                      onBlur={this.handleValidatePhone}
                    />
                  </div>
                  <span className="errorMsg mobile-msg">
                    {this.state.phoneErr}
                  </span>
                </div>
              </div>
            </div>
            <div className="contact-form__container-row">
              <div className="contact-form__container-row--input-container">
                <label htmlFor="email" className="label-form">
                  Email
                </label>
                <input
                  name="email"
                  type="text"
                  pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+"
                  className="contact-form__container-row--input-text regular"
                  title="email@address.com"
                  required
                  value={this.state.email}
                  placeholder="e-mail"
                  onChange={(e) => this.handleChangeOption(e)}
                />
                <span className="errorMsg">{this.state.emailErr}</span>
              </div>
            </div>
            <div className="contact-form__container-row">
              <div className="contact-form__container-row--input-container">
                <label htmlFor="message" className="label-form">
                  Message
                </label>
                <textarea
                  name="message"
                  className="contact-form__container-row--input-text text-message regular"
                  type="text"
                  placeholder="How can we help you?"
                  maxLength="1000"
                  value={this.state.message}
                  onChange={(e) => this.handleChangeOption(e)}
                  required
                />
                <span className="errorMsg">{this.state.messageErr}</span>
              </div>
            </div>
            <div className="contact-form__container-row">
              <span className="errorMsg">{this.state.recaptchaError}</span>
            </div>
            <br />
            <div className="contact-form__button-container">
              <button
                id="contact-us-send-cta"
                data-ga-id="contact-us-send-cta"
                className="cta"
                type="submit"
                value="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
