import React from "react";
import PageHero from '../../PageHero';

const imagesList = {
  desktop: require("../../../img/bg__hero-desktop.png"),
  mobile: require("../../../img/bg__hero-mobile.png")
};
const CareersHero = () => (
  <PageHero
    bold="DREAM JOB"
    customElement="hero_search_wrapped"
    divClass="careers__hero"
    imagesList={imagesList}
    title="GET YOUR"
    subtitle=""
    />
)

export default CareersHero;
