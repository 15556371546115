import React from "react";
import LogoGrid from "../LogoGrid/LogoGrid";

const RegionalGlobalGrid = () => {
    return (
        <LogoGrid
          title="Regional/Global Solutions <br> <span>for Direct Client Entities</span>"
          logos = {[
            {image: require("../../../img/logos/01-gs-P_G.png"), alt: "P&G"},
            {image: require("../../../img/logos/02-gs-Nestle.png"), alt: "Nestlé"},
            {image: require("../../../img/logos/03-gs-McD.png"), alt: "MacDonalds"},
            {image: require("../../../img/logos/04-gs-Rolls-Royce.png"), alt: "Royce"},
            {image: require("../../../img/logos/05-gs-HP_Enterprice.png"), alt: "HP Enterprice"},
            {image: require("../../../img/logos/06-gs-Kraft.png"), alt: "Kraft"},
            {image: require("../../../img/logos/07-gs-Loreal.png"), alt: "L'Oréal"},
            {image: require("../../../img/logos/08-gs-Sanofi.png"), alt: "Sanofi"},
            {image: require("../../../img/logos/09-gs-Toyota.png"), alt: "Toyota"},
            {image: require("../../../img/logos/10-gs-Renault.png"), alt: "Renault"},
            {image: require("../../../img/logos/11-gs-Walmart.png"), alt: "Walmart"},
            {image: require("../../../img/logos/12-gs-Kelloggs.png"), alt: "Kelloggs"},
            {image: require("../../../img/logos/13-gs-GM.png"), alt: "GM"},
            {image: require("../../../img/logos/14-gs-Jaguar.png"), alt: "Jaguar"},
            {image: require("../../../img/logos/15-gs-Carrefour.png"), alt: "Carrefour"},
            {image: require("../../../img/logos/16-gs-GSK.png"), alt: "GSK"},
            {image: require("../../../img/logos/17-gs-Canon.png"), alt: "Canon"},
            {image: require("../../../img/logos/18-gs-UEFA.png"), alt: "UEFA"},
            {image: require("../../../img/logos/19-gs-FIAT.png"), alt: "FIAT"},
            {image: require("../../../img/logos/20-gs-Land-Rover.png"), alt: "Land Rover"},
            {image: require("../../../img/logos/21-gs-AXA.png"), alt: "AXA"},
            {image: require("../../../img/logos/22-gs-Orange.png"), alt: "Orange"},
            {image: require("../../../img/logos/23-gs-ASDA.png"), alt: "ASDA"},
            {image: require("../../../img/logos/24-gs-Lancel.png"), alt: "Lancel"}
          ]}
        />
    )
}

export default RegionalGlobalGrid;