import React from "react";
import { Link } from "react-router-dom";
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";

const objectImages = { desktop: require("../../../img/ph-about-us-d.jpg"), mobile: require("../../../img/ph-about-us-m.jpg")}
const AboutMainHero = () => (
  <div className="about__masthead">
    <div className="abs-dotted dotted-bg__orange"></div>
    <div className="about__masthead-container">
      <blockquote className="about__title strong-text">
        We are a never sleeps machine of creation that continuously grows and mutates to become a more efficient and collaborative system.
      </blockquote>
      <Link
          to={{ pathname: "https://www.linkedin.com/company/pgd/jobs" }} 
          target="_blank"
          className="about-cta"
        >
          Apply Now
      </Link>
    </div>
    <div
      className="about__masthead-bg"
      role="img"
      aria-label="laptop hero"
    >
      <ResponsiveImage objectImages={objectImages} />
    </div>
  </div>
);

export default AboutMainHero;
