import React, { useEffect, useState } from "react";
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import ICIMSCard from "../../ICIMSCard/ICIMSCard";


const CareersModal = ({ isVisible, children }) => {

    const [scrollY, setScrollYPosition] = useState(0);

    useScrollPosition(({ prevPos, currPos }) => {
        setScrollYPosition(currPos.y);
    });

    const handleDisablingScroll = () => {
        const { body } = document;

        if (isVisible === true) {
            body.style.position = 'fixed';
            body.style.top = `${scrollY}px`;
        } else {
            if (body.style.position === 'fixed' && body.style.top !== '') {
                const scrollY = body.style.top;
                body.style.position = '';
                body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }
        }
    };

    useEffect(() => {
        handleDisablingScroll();
        // return cleanup function to change the styles in body element in case we wanna change the modal and used in other section with other
        return () => {
            const body = document.body;
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }, [isVisible])

    return (
        isVisible === true ?
            <div className="careers-modal" >
                <div className="card-container">
                    {
                        children ? children :
                            <ICIMSCard />
                    }
                </div>
            </div >
            : null
    )

}

export default CareersModal
