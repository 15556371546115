import React from "react";

const HomeContent = () => (
  <div className="home__wrapper">
    <div className="home__wrapper-content">
      <div className="home__title regular">
        <strong className="home__title-bold GothamBlack">we are</strong>PUBLICIS GLOBAL DELIVERY
      </div>
      <div className="home__lineDivider"></div>
      <div className="home__subtitle regular">
        The global production partner for the world's leading brands.
      </div>
    </div>
  </div>
)

export default HomeContent;
