import React, { Component } from "react";
import { Router, Route, Switch, Link } from "react-router-dom";
import AboutUs from "../AboutUs/AboutUs";
import Contact from "../Contact/Contact";
import Careers from "../Careers/Careers";
import Home from "../Home/Home";
import Events from "../Events";
import Event from "../Events/Event";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import "../App.scss";
import history from "../history";
import Tracking from "../Tracking";
import PositionDetails from "../Careers/PositionDetails/PositionDetails";
import ThankYouPage from "../ThankYouPage/ThankYouPage";
import PrivacyPolicy from "../PrivacyPolicy";

class Site extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="responsive-container">
          <Nav />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/events" exact component={Events} />
            <Route path="/events/:id" component={Event} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/contact-us" component={Contact} />
            <Route path='/open-positions' element={redirectToLinkedIn}/>
            <Route path="/job/:id" component={redirectToLinkedIn} />
            <Route path="/thank-you" component={ThankYouPage} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
          <Footer />
        </div>
        <Tracking />
      </Router>
    );
  }
}

const redirectToLinkedIn = () => (
  <>
    <div class="home__page">
      <div class="home__wrapper">
        <div class="home__wrapper-content">
          <div class="home__title regular">
            <strong class="home__title-bold GothamBlack">we are</strong>PUBLICIS GLOBAL DELIVERY
          </div>
          <div class="home__lineDivider"></div>
          <div class="home__subtitle regular">Visit our <Link
              to={{ pathname: "https://www.linkedin.com/company/pgd/jobs" }} 
              target="_blank"
              className="about-cta"
            >
            Jobs page on LinkedIn.
          </Link></div></div></div>
    </div>
  </>
)


export default Site;
