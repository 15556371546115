import React, { Component } from 'react'
import { Link } from "react-router-dom";
import * as wbApi from '../../../API/CareersApi'
import { isJson } from '../../../../utils/helpers'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import {
  getCountries as getCountriePhoneAPI,
  getCountryCallingCode,
} from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import getCountries from '../../../getCountries'
import CareersModal from '../../CareersModal/CareersModal'

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
)
const validInputRegex = /^([a-z-À-ÿ\u00f1\u00d1])+\s*([a-z-À-ÿ\u00f1\u00d1])+\s*([a-z-À-ÿ\u00f1\u00d1])*$/i
class Apply extends Component {
  state = {
    data: {},
    firstName: '',
    lastName: '',
    country: '',
    countryCode: '',
    countryCodeNumber: '',
    email: '',
    mobile: '',
    flagHandler: '',
    terms: '',
    firstNameErr: '',
    lastNameErr: '',
    countryErr: '',
    emailErr: '',
    mobileErr: '',
    termsErr: '',
    generalError: '',
    linkedin: '',
    linkedinErr: '',
    isModalActive: false,
    srUuid: '',
  }

  handleValidatePhone = () => {
    if (this.state.mobile !== '' && typeof this.state.mobile !== 'undefined') {
      if (
        isValidPhoneNumber(this.state.countryCodeNumber + this.state.mobile)
      ) {
        this.setState({ mobileErr: '' })
      } else {
        this.setState({ mobileErr: 'Invalid phone number' })
      }
    } else {
      this.setState({
        mobileErr: 'Please enter a phone number',
      })
    }
  }

  handleChangeOptionPhone = (e) => {
    this.setState({
      mobile: e.target.value,
    })
  }

  handleChangeOption = (e) => {
    const { name, value } = e.target
    const errState = {}

    switch (name) {
      case 'firstName':
        errState.firstNameErr =
          value.length <= 1
            ? 'Please enter your name.'
            : '' || !value.match(validInputRegex)
              ? 'Name must be letters only.'
              : ''
        break
      case 'lastName':
        errState.lastNameErr =
          value.length <= 1
            ? 'Please enter your last name.'
            : '' || !value.match(validInputRegex)
              ? 'Name must be letters only.'
              : ''
        break
      case 'email':
        errState.emailErr =
          value.length <= 1
            ? 'Please enter your email.'
            : '' || validEmailRegex.test(value)
              ? ''
              : 'Email is not valid.'
        break
      case 'linkedin':
        let regexLinkedinUrl = /^http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/i;
        errState.linkedinErr = regexLinkedinUrl.test(value) ? '' : 'Url is not valid.'
        break
      default:
        break
    }
    this.setState({
      ...errState,
      [e.target.name]: e.target.value,
    })
  }

  handleChangeOptionCheckbox = ({ target }) => {
    const termsErr = target.checked ? '' : 'Please accept terms and conditions.'

    this.setState({
      termsErr,
      [target.name]: target.checked,
    })
  }

  handleChangeOptionFlag = (value) => {
    let countryCode = document.getElementsByClassName(
      'PhoneInputCountrySelect'
    )[0].value
    let codeNumber =
      countryCode === 'ZZ' ? '' : '+' + getCountryCallingCode(countryCode)

    this.setState({
      countryCodeNumber: codeNumber,
      flagHandler: value,
    })
  }

  handleChangeOptionCountry = ({ target }) => {
    const countryErr = target.value === '' ? 'Please select a country.' : ''
    const codeNumber =
      target.value === '' || target.value === 'ZZ'
        ? ''
        : '+' + getCountryCallingCode(target.value)

    this.setState({
      countryErr,
      countryCode: target.value,
      flagHandler: '',
      country: target.selectedOptions[0].text,
      countryCodeNumber: codeNumber,
    })
  }

  componentDidMount() {
    //Prepopulate the Country of the user on the Country dropdown
    document
      .getElementsByClassName('PhoneInputInput')[0]
      .setAttribute('disabled', false)
    getCountries()
      .then((response) => response.json())
      .then((data) => {
        //Get the country code
        const selectedCountryCode = data.country_code
        if (
          selectedCountryCode !== '' &&
          typeof selectedCountryCode !== 'undefined'
        ) {
          this.setState({
            countryCode: selectedCountryCode,
            country: data.country_name,
            countryCodeNumber: data.country_calling_code,
          })
        }
      })
  }

  cleanFormState = (activeModal = false, generalErrorMessage = '') => {
    this.setState({
      firstName: '',
      lastName: '',
      country: '',
      countryCode: '',
      email: '',
      mobile: '',
      terms: '',
      linkedin: '',
      generalError: generalErrorMessage,
      isModalActive: activeModal,
    })
  }

  handleSubmit = (e) => {
    const applyLink = "/thank-you";

    /**`https://careers-prodigious.icims.com/jobs/id/title/job?mode=prepopulate&iiem=${
      this.state.email
    }&iifn=${this.state.firstName}&iiln=${this.state.lastName}&iimp=${
      this.state.countryCodeNumber + this.state.mobile
    }&iihct=${this.state.country}&iihz=${this.state.countryCode}`*/

    e.preventDefault()

    if (this.validateForm()) {
      //set jobId and jobLocation on new object to avoid state conflicts
      const pageLoad = Object.assign(this.state, {
        jobId: this.props.job.data.jobId,
        jobLocation: this.props.job.data.country,
        srUuid: this.props.job.data.srUuid,
        linkedinUrl: this.props.job.data.linkedin
      })
      //if form validated Post request to BE

      let _self = this;
      
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_SITE_KEY, { action: 'demo' })
          .then(function (token) {
            console.log('token:', token);
            //wbApi.postContactUs("mail", data,token); 
            wbApi
              .postApplyJobForm('applyForJobLead/register', pageLoad, token)
              .then((res) => {
                console.log(`RESPONSE IN FE: ${res}`)
                if (isJson(res)) {
                  const message = JSON.parse(res)
                  console.log("message.code: ", message.code)
                  if (message.code === 400) {
                    console.log(
                      '%cERROR DUPLICATE APPLICATION',
                      'color: coral; font-weight: bold;'
                    )
                    _self.cleanFormState(
                      false,
                      'You have already applied to this position.'
                    )
                    return false
                  }
                  if (message.code === 403) {
                    console.log(
                      '%cERROR TOKEN',
                      'color: coral; font-weight: bold;'
                    )
                    _self.cleanFormState(
                      false,
                      'Captcha could not be validated'
                    )
                    return false
                  }
                }
                _self.cleanFormState()
                console.log('%cSUCCESS', 'color: lightgreen; font-weight: bold;')
                //then set state to empty-clean input fields
                window.location.href = _self.generateUrl(applyLink)
              })
          });
      });
    } else {
      return false
    }
  }
  //Form validation on submit
  validateForm = () => {
    let formIsValid = true
    if (!this.state.firstName) {
      formIsValid = false
      this.setState({ firstNameErr: 'Please provide your name.' })
    }

    if (typeof this.state.firstName !== 'undefined') {
      if (!this.state.firstName.match(validInputRegex)) {
        formIsValid = false
        this.setState({ firstNameErr: 'Name must be letters only.' })
      }
    }

    if (!this.state.lastName) {
      formIsValid = false
      this.setState({ lastNameErr: 'Please provide your last name.' })
    }

    if (typeof this.state.lastName !== 'undefined') {
      if (!this.state.lastName.match(validInputRegex)) {
        formIsValid = false
        this.setState({ lastNameErr: 'Last name must be letters only.' })
      }
    }

    if (this.state.country === '' || typeof this.state.country === undefined) {
      formIsValid = false
      this.setState({ countryErr: 'Please select a country.' })
    }

    if (!this.state.email) {
      formIsValid = false
      this.setState({ emailErr: 'Please provide your email address.' })
    }

    if (!this.state.terms) {
      formIsValid = false
      this.setState({ termsErr: 'Please accept terms and conditions.' })
    }

    if (this.state.mobile === '' || this.state.mobile === 'undefined') {
      formIsValid = false
      this.setState({ mobileErr: 'Please enter a phone number.' })
    }

    if (
      this.state.firstNameErr === '' &&
      this.state.lastNameErr === '' &&
      this.state.countryErr === '' &&
      this.state.mobileErr === '' &&
      this.state.linkedinErr === '' &&
      this.state.emailErr === ''
    ) {
      return formIsValid
    }
  }

  generateUrl = (url) => {
    return url
      .replace('id', this.props.job.data.jobId)
      .replace('title', this.props.job.data.jobTitle)
  }

  handleReferral = (e) => {
    e.preventDefault()
    const referralLink =
      `https://jobs.smartrecruiters.com/external-referrals/company/PublicisGroupe/publication/${this.props.job.data.srUuid}?dcr_id=DCRA1`;
    this.setState({ isModalActive: false });
    window.location.href = this.generateUrl(referralLink);
  }

  render() {
    return (
      <>
        <CareersModal isVisible={this.state.isModalActive} />
        <div className="career-apply__subtitle regular">
          <p>
            Please enter your personal information to apply for this position.
          </p>
        </div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="career-apply__container-row">
            <div className="career-apply__container-row--input-container">
              <input
                name="firstName"
                className="career-apply__container-row--input-text regular"
                type="text"
                pattern="[a-zA-ZÀ-úñÑ\s]+"
                title="Letters only"
                placeholder="First name"
                value={this.state.firstName}
                onChange={(e) => this.handleChangeOption(e)}
                required
              />
              <span className="errorMsg">{this.state.firstNameErr}</span>
            </div>
            <div className="career-apply__container-row--input-container">
              <input
                name="lastName"
                className="career-apply__container-row--input-text regular"
                type="text"
                pattern="[a-zA-ZÀ-úñÑ\s]+"
                title="Letters only"
                placeholder="Last name"
                required
                value={this.state.lastName}
                onChange={(e) => this.handleChangeOption(e)}
              />
              <span className="errorMsg">{this.state.lastNameErr}</span>
            </div>
          </div>
          <div className="career-apply__container-row">
            <div className="career-apply__container-row-country">
              <select
                name="country"
                className="country-selector career-apply__container-row--input-text"
                value={this.state.countryCode}
                onChange={(e) => this.handleChangeOptionCountry(e)}
                required
              >
                <option value="">Select a country</option>
                {getCountriePhoneAPI().map((country) => (
                  <option key={country} value={country}>
                    {en[country]}
                  </option>
                ))}
              </select>
              <span className="errorMsg bold">{this.state.countryErr}</span>
            </div>
            <div className="career-apply__container-row-mobile">
              <PhoneInput
                className="phoneSelector career-apply__container-row--input-text input-flag regular"
                name="country code"
                placeholder={this.state.countryCodeNumber}
                limitMaxLength={true}
                defaultCountry={this.state.countryCode}
                value={this.state.flagHandler}
                onChange={(value) => this.handleChangeOptionFlag(value)}
                required
              />
              <span className="input-separator" />
              <div className="input-number">
                <input
                  className="phoneSelector career-apply__container-row--input-text input-number regular"
                  name="mobile"
                  type="number"
                  placeholder="Mobile number"
                  value={this.state.mobile}
                  onChange={(value) => this.handleChangeOptionPhone(value)}
                  onBlur={this.handleValidatePhone}
                />
              </div>
              <span className="errorMsg mobile-msg">
                {this.state.mobileErr}
              </span>
            </div>
          </div>
          <div className="career-apply__container-row">
            <div className="career-apply__container-row--input-container">
              <input
                name="email"
                type="text"
                pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+"
                className="career-apply__container-row--input-text regular"
                title="email@address.com"
                required
                value={this.state.email}
                placeholder="e-mail"
                onChange={(e) => this.handleChangeOption(e)}
              />
              <span className="errorMsg">{this.state.emailErr}</span>
            </div>
          </div>
          <div className="input-check">
            <label className="input-check__title regular">
              <input
                className="input-check__item"
                name="terms"
                type="checkbox"
                required
                checked={this.state.terms}
                onChange={(e) => this.handleChangeOptionCheckbox(e)}
              />
              Accept
              <a
                className="input-check__link bold"
                target="_blank"
                type="application/pdf; length=4398858"
                href="../assets/pdf/POLMMS.pdf"
              >
                {' '}
                Terms &amp; Conditions
              </a>
              &nbsp;&amp;
              <Link
                className="input-check__link bold"
                to="/privacy-policy"
              >
                {' '}
                Privacy Policy
              </Link>
            </label>
          </div>
          <span className="errorMsg">{this.state.termsErr}</span>
          <span className="errorMsg">{this.state.generalError}</span>
          <div className="career-apply__button-container">
            <button
              id="apply-for-job-cta"
              data-ga-id="apply-for-job-ga-cta"
              className="cta cta-ternary"
              type="submit"
            >
              Start Process
            </button>
            <button
              id="refer-a-friend-cta"
              data-ga-id="refer-a-friend-ga-cta"
              className="cta cta-ternary"
              onClick={this.handleReferral}
            >
              Refer a Friend
            </button>
          </div>
        </form>
      </>
    )
  }
}

export default Apply
