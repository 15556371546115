import React from "react";
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";

const objectBGImage = [{ desktop: require("../../../img/bg-publicis-groupe-desktop.jpg")}, { mobile: require("../../../img/bg-publicis-groupe-mobile.jpg") }];
const objectSectionImages = { desktop: require("../../../img/publicis-groupe-d.jpg"), mobile: require("../../../img/publicis-groupe-m.jpg") };

const PublicisHero = () => (
  <section className="publicis__container">
    <ResponsiveImage objectImages={objectBGImage[0]} containerClass={"publicis__lines__bg desktop"}/>
    <ResponsiveImage containerClass="publicis__container-bg" objectImages={objectSectionImages} ariaLabel={"prodigious building"} />
    <div className="publicis__container-text">
      <ResponsiveImage objectImages={objectBGImage[1]} containerClass={"publicis__lines__bg mobile"}/>
      <div className="publicis__content">
        <div className="publicis__title">
          <p className="publicis__title publicis__title-normal regular">We are</p>
          <p className="publicis__title publicis__title-bold strong-text">publicis<br></br> groupe</p>
        </div>
        <p className="publicis__subtitle regular">
          The 1st communication group in the world, leading transformations since 1926.
        </p>
      </div>
    </div>
  </section>
);

export default PublicisHero;
