import React from "react";
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";

const objectImages = { desktop: require("../../../img/ph-partners-d.png"), mobile: require("../../../img/ph-partners-d.png")}

const MakingImpossible = () => (
  <section className="making__wrapper">
    <div className="making__container-title">
      <div
        className="making__container-bg"
        role="img"
        aria-label="making the impossible"
      >
      </div>
      <p className="making__title strong-text">
        WE PROVIDE OFFSHORE & NEARSHORE SOLUTIONS
      </p>
    </div>
    <div className="making__container-subtitle">
      <p className="making__subtitle regular">for our partners</p>
    </div>
  </section>
);

export default MakingImpossible;
