import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dompurify from 'dompurify';
import { shortenJobOverview, categoryNameFormatted } from '../../../../utils/helpers.js'
import ResponsiveImage from "../../../ResponsiveImage/ResponsiveImage";

const hotPositionImage = { desktop: require("../../../../img/hotPositions.png") };
const hotPositionBg= { desktop: require("../../../../img/bg-hot-positions.png"), mobile: require("../../../../img/bg-hot-positions-m.png")};
const sanitizer = dompurify.sanitize;
const CareersHotPositions = ({ data, dataLoaded, dataError }) => {
  const [state, setState] = useState({
    data: data,
    items: 3,
    loading: false
  });

  useEffect(() => {
    setState({
      ...state,
      data: data,
    });
  }, [data]);

  if(dataLoaded) {
    if (state.data.length > 0) {
      return (
        <div className="hot-positions__container">
          <ResponsiveImage objectImages={hotPositionImage} containerClass={"hot-positions__overlap"} altText="people working"/>
          <ResponsiveImage containerClass="hot-positions__container-bg" objectImages={hotPositionBg} ariaLabel={"Hot Positions"} />
          <h2 className="hot-positions__container-title">HOT POSITIONS</h2>
          <div className="hot-positions__wrapper" id="hot-positions_scroll_target">
            {state.data.map((job) => (
              <div className="hot-positions__content" key={job.jobId}>
                <div className="hot-positions__content-category bold">
                  {categoryNameFormatted(job.jobCategory)}
                </div>
                <h3 className="hot-positions__content-title bold">{job.jobTitle}</h3>
                <div className="hot-positions__content-location">
                  <img
                    src={require("../../img/location_icon.png")}
                    alt="location icon"
                    className="position__icon"
                  ></img>
                  <p className="position__country regular">
                    {job.country}
                  </p>
                </div>
                <p
                  className="position__text regular"
                  dangerouslySetInnerHTML={shortenJobOverview(sanitizer(job.overview))}
                ></p>
                <div className="position__link strong-text">
                  <Link
                    className="see_more_click"
                    id={`${job.jobId}`}
                    to={{
                      pathname: `/job/${job.jobId}`,
                      state: {
                        jobTitle: job.jobTitle,
                      },
                    }}
                  >
                    See More
                  </Link>
                </div>
              </div>
            ))}
            {state.loading ? (
              <p className="App-intro">Searching more jobs ...</p>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="hot-positions__no-results">
        <img
          className="hot-positions__glass-icon"
          src={require("../../../Careers/img/Not_Found.png")}
          alt="no results found magnifying glass"
        />
        <div className="hot-positions__no-results-title bold">
          No Results Found
        </div>
        <div className="hot-positions__no-results-content medium" id="hot-positions_scroll_target">
          Sorry, we couldn't find any results matching that search criteria
        </div>
      </div>
    );
  }
  else {
    if(dataError) {
      return (
        <div className="hot-positions__no-results">
          <img
            className="hot-positions__router-icon"
            src={require("../../../Careers/img/issues.png")}
            alt="having technical router"
          />
          <div className="hot-positions__no-results-title bold">
          Technical Difficulties
          </div>
          <div className="hot-positions__no-results-content medium" id="hot-positions_scroll_target">
          We are currently having technical difficulties loading the current positions.<br />Please try again in a few minutes
          </div>
        </div>
      );
    } else {
      return (
      <div className="hot-positions__no-results">
        <p>Loading</p>
        <img src={require("../../../../img/loading-positions.gif")} alt="Loading positions" className="hot-positions__loading-results-img" />
      </div>
      );
    }
  }


};

export default CareersHotPositions;
