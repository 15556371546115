import React from 'react'
import { Link } from 'react-router-dom';
import { getLocalDate } from '../../../utils/date';

const EventCard = ({ event }) => {
  const { id, title, description, image, date, url } = event;
  const currentDate = getLocalDate(date);
  const month = new Date(currentDate).toLocaleString('default', { month: 'long' });
  const day = ('0' + new Date(currentDate).getDate()).slice(-2);

  return (
    <div className="event-card">
      <div className="event-card__image">
        <img src={image} alt={title} />
      </div>
      <div className="event-card__content">
      <div className="event-card__content-date strong-text">
        <div className="event-card__content-date-month">{month.slice(0, 3)}</div>
          <div className="event-card__content-date-day">{day}</div>
        </div>
        <div className="event-card__content-info">
          <h3 className="event-card__content-title strong-text">{title}</h3>
          <div className="event-card__content-description regular">
            <div>{description}</div>
            {url ?
              <a className="bold" href={url} target="_blank" rel="noopener noreferrer">View event</a> :
              <Link className="bold" to={`event/${id}`}>View event</Link>}
          </div>
        </div>
      </div>
    </div>


  )
}


export default EventCard;
