import { useState, useEffect } from 'react';
import data from '../data/events.json';

export const useEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(data);
  }, []);

  return events;
};