import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import ResponsiveImage from "./../ResponsiveImage/ResponsiveImage";

const PageHero = ({ divClass, title, bold, subtitle, imgAltText, imagesList, cta, customElement }) => {
  const responsivePageHero = imagesList ? "responsive" : '';

  return (
    <div className={`hero__wrapper masthead ${divClass}`}>
      <div className={`hero__masthead hero__masthead-${responsivePageHero}`}>
        <div className={`hero__masthead-bg hero__masthead-bg-${responsivePageHero}`}>
          {imagesList && (
            <ResponsiveImage altText={imgAltText} objectImages={imagesList} />
          )}
        </div>
        <div className={`hero__masthead-container hero__masthead-container-${responsivePageHero}`}>
          <h1 className="masthead__title strong-text">
            {title} <span className="masthead__title-bold">{bold}</span>
          </h1>
          <h2 className="masthead__subtitle regular">
            {subtitle}
          </h2>
          {cta && (
            <Link className="masthead__cta" to={cta.url}>{cta.copy}</Link>
          )}
          {customElement && (
            <div id={customElement}></div>
          )}
        </div>
      </div>
    </div>
  );
};

PageHero.defaultProps = {
  cta: null,
  customElement:null,
  divClass: '',
  imgAltText: '',
  imagesList: null,
};

PageHero.propTypes = {
  bold: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    copy: PropTypes.string,
    url: PropTypes.string,
  }),
  customElement:PropTypes.string,
  divClass: PropTypes.string,
  imgAltText: PropTypes.string,
  imagesList: PropTypes.shape({
    objectImages: PropTypes.object,
    dataClass: PropTypes.string,
    altText: PropTypes.string
  }),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default PageHero;
