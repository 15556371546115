import React from 'react';
import EventInfo from './EventInfo';

const Event = () => {
  return (
    <div className="event__wrapper">
      <EventInfo />
    </div>
  );
};

export default Event;
