const API_URL = process.env.REACT_APP_API_URL;
const authToken = process.env.AUTH_TOKEN;
const authApiKey = process.env.AUTH_API_KEY;

if (process.env !== 'production') {
  console.log("API_URL = " + API_URL);
}

export function getBasicAuthHeaders() {
  let basicAuthHeaders = new Headers();
  basicAuthHeaders.append("Authorization", authToken);
  basicAuthHeaders.append("AUTH_API_KEY" , authApiKey);
  return basicAuthHeaders;
}

export function get(action, params) {
  const url = API_URL + action + params;

  let headers = getBasicAuthHeaders();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  const result = fetch(url, {
    headers,
  }).then((result) => result.json())
    .catch(function (error) {
      throw new Error(error);
    });
  return result;
}

export function postContactUs(action, params,token) {
  let headers = getBasicAuthHeaders();
  headers.set("Authorization", token);
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  let urlEncoded = new URLSearchParams();
  urlEncoded.append("email", params.email);
  urlEncoded.append("name", params.name);
  urlEncoded.append("phone", params.phone);
  urlEncoded.append("message", params.message);
  urlEncoded.append("country", params.country);
  urlEncoded.append("countryCode", params.countryCode);

  const url = API_URL + action;
  const options = {
    method: "POST",
    headers,
    body: urlEncoded,
  };

  return fetch(url, options);
}

export async function postApplyJobForm(action, params, token) {

  let headers = getBasicAuthHeaders();
  headers.set("Authorization", token);
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  let urlEncoded = new URLSearchParams();
  urlEncoded.append("firstName", params.firstName);
  urlEncoded.append("lastName", params.lastName);
  urlEncoded.append("email", params.email);
  urlEncoded.append("country", params.country);
  urlEncoded.append("mobile", params.mobile);
  urlEncoded.append("jobId", params.jobId);
  urlEncoded.append("jobLocation", params.jobLocation);
  urlEncoded.append("srUuid", params.srUuid);
  urlEncoded.append("linkedinUrl", params.linkedin);
  const url = API_URL + action;
  const options = {
    method: "POST",
    headers,
    body: urlEncoded,
  };
  return fetch(url, options)
    .then((response) => response.text())
    .catch((err) => console.log("ERROR: " + err));
}

export function getUrl() {
  return API_URL;
}
