import React from "react";

const ResponsiveImage = ({ objectImages, imgClass, containerClass, altText, ariaLabel }) => {
  const { desktop, mobile } = objectImages;
  let mainAsset = desktop;

  if(mobile && !desktop) {
    mainAsset = mobile;
  }

  return (
    <picture className={containerClass}>
      {mobile && desktop
        ? <>
            <source srcSet={mainAsset} media="(min-width: 768px)"></source>
            <source srcSet={mobile} media="(min-width: 0px)"></source>
          </>
        : <source srcSet={mainAsset} media="(min-width: 0px)"></source>
      }

      <img
        className={imgClass}
        srcSet={mainAsset}
        alt={altText}
        aria-label={ariaLabel}
      />
    </picture>
  )
}

export default ResponsiveImage