import React from "react";

const OfferingSolutions = () => (
  <section className="offering__container">
    <div className="offering__wrapper-titles">
      <div className="offering__title">
        <p className="offering__title-p regular">OFFERING GLOBAL<br/>IDEAS & SOLUTIONS</p>
      </div>
      <div className="offering__subtitle regular">
        We build partnerships based on our expertise, and we are committed to innovation and improvement.
      </div>
    </div>
    <div className="offering__wrapper-content">
      <div className="offering__info-container">
        <div className="offering__info-icon icon__tech">
          <img
            src={require("../../../img/tech__icon.png")}
            alt="real tech solutions"
          ></img>
        </div>
        <div className="offering__info-title medium">global tech solutions</div>
        <div className="offering__info-text text__tech regular">
          <ul>
            <li>Solution Architecture</li>
            <li>Site/Application Development&nbsp;& Maintenance</li>
            <li>Content Management</li>
            <li>Data Management</li>
            <li>Cloud/Infrastructure Management</li>
          </ul>
        </div>
      </div>
      <div className="offering__info-container">
        <div className="offering__info-icon icon__creative">
          <img
            src={require("../../../img/creative__icon.png")}
            alt="real creative solutions"
          ></img>
        </div>
        <div className="offering__info-title medium">global creative solutions</div>
        <div className="offering__info-text text__creative regular">
          <ul>
            <li>Creative Ideation</li>
            <li>Customer Experience Design</li>
            <li>Motion</li>
            <li>Creative & Campaign Production</li>
            <li>Video Post-Production</li>
          </ul>
        </div>
      </div>
      <div className="offering__info-container">
        <div className="offering__info-icon icon__media">
          <img
            src={require("../../../img/media__icon.png")}
            alt="real media solutions"
          ></img>
        </div>
        <div className="offering__info-title medium">global media solutions</div>
        <div className="offering__info-text text__media regular">
          <ul>
            <li>Planning</li>
            <li>Media Buying</li>
            <li>Campaign Management</li>
            <li>Campaign Optimization</li>
            <li>Data Analytics</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default OfferingSolutions;
