import React from "react";
import DOMPurify from 'dompurify';

const LogoGrid = (props) => {
    const listItems = props.logos.map((logo) =>
        <li key={logo.alt}><img src={logo.image} alt={logo.alt} /></li>
    );

    return (
        <section className="LogoGrid-container">
            <h2 className={props.classH2} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.title)}}></h2>
            <ul className="LogoGrid-container--logos">
                {listItems}
            </ul>
        </section>
    )
}

export default LogoGrid;
