import React from 'react';
import { useParams } from 'react-router-dom';
import { useEvents } from '../../../../hooks/events';

const EventInfo = () => {
  const events = useEvents();
  const { id } = useParams();
  const event = events.find((event) => event.id.toString() === id);
  const { title, date, country, image, description } = event || {};

  return (
    <div className="event-info">
      <div className="event-date">
        {date && Intl.DateTimeFormat('en-US', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }).format(new Date(date))}
        , {country}
      </div>
      <h2 className="event-title">{title}</h2>
      <div className="event-content">
        <div>
          <img src={image} alt={title} />
        </div>
        <div className="regular">
          {description}
        </div>
      </div>
    </div>
  );
};

export default EventInfo;
