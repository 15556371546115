import React from "react";

const MagicMakerContent = () => (
    <section className="about__magic-container">
      <div className="about__magic-title"><p className="magic__title magic__title-normal regular">GLOBAL<br></br>PLATFORM</p>
      <p className="magic__title magic__title-bold strong-text">LOCAL<br></br>DELIVERY</p></div>
      <div className="about__magic-text regular">
      A cross-media transformation agent, based in Argentina, Colombia, Costa Rica, and Mexico, to provide centralized expertise of all Publicis Global Services' capabilities to enable consistent and standardized delivery across Media, Production, Commerce, Content, Data & Technology.
      </div>
    </section>
)

export default MagicMakerContent;
