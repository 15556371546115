import React from "react";
import LogoGrid from "../LogoGrid/LogoGrid";

const NearOffshoreGrid = () => {
    return (
        <LogoGrid
          title="Near/Offshore Solutions <br> <span>for Group Agencies</span>"
          classH2="near"
          logos = {[
            {image: require("../../../img/logos/01-os-Digitas.png"), alt: "Digitas"},
            {image: require("../../../img/logos/02-os-moxie.png"), alt: "Moxie"},
            {image: require("../../../img/logos/03-os-mrg.png"), alt: "MRG"},
            {image: require("../../../img/logos/04-os-Saatchi_Saatchi.png"), alt: "Saatchi & Saatchi"},
            {image: require("../../../img/logos/05-os-Publicis_Health.png"), alt: "Publicis Health"},
            {image: require("../../../img/logos/06-os-Publicis_Sapient.png"), alt: "Publicis Sapient"},
            {image: require("../../../img/logos/07-os-performics.png"), alt: "Performics"},
            {image: require("../../../img/logos/08-os-Sapient_RZ.png"), alt: "Sapient Razorfish"},
            {image: require("../../../img/logos/09-os-spark.png"), alt: "Spark"},
            {image: require("../../../img/logos/10-os-starcom.png"), alt: "Starcom"},
            {image: require("../../../img/logos/11-os-Zenith.png"), alt: "Zeni"}
          ]}
        />
    )
}

export default NearOffshoreGrid;
