import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dompurify from 'dompurify';
import { shortenJobOverview, categoryNameFormatted } from '../../../../utils/helpers.js'

const Careers = (props) => {
  const sanitizer = dompurify.sanitize;

  const [state, setState] = useState({
    search: "",
    data: props.data,
    items: 6,
    dataloaded: false,
    itemsLeft: props.itemsLeft,
  });

  useEffect(() => {
    setState({
      ...state,
      data: props.data,
      itemsLeft: props.itemsLeft,
      items: 6,
    });
  }, [props]);

  const loadMore = () => {
    let moreItems = window.innerWidth < 768 ? 3 : 6;
    if (state.itemsLeft > 0) {
      setState({
        ...state,
        items: state.items + moreItems,
        loading: false,
        itemsLeft: state.itemsLeft - moreItems,
      });
    }
  };

  if (state.data.length > 0) {
    return (
      <div className="more-positions__container">
        {state.dataloaded && state.data.length === 0 && (
          <p className="App-intro">
            Sorry, we couldn't find any results matching that search criteria
          </p>
        )}
        <h2 className="more-positions__container-title regular">
          MORE OPPORTUNITIES
        </h2>
        <div className="more-positions__wrapper">
          {state.data.slice(0, state.items).map((job) => (
            <div className="more-positions__content" key={job.jobId}>
              <div className="more-positions__content-category bold">
                {categoryNameFormatted(job.jobCategory)}
              </div>
              <h3 className="more-positions__content-title bold">{job.jobTitle}</h3>
              <div className="more-positions__content-location">
                <img
                  src={require("../../img/location_icon.png")}
                  alt="location icon"
                  className="position__icon"
                ></img>
                <p className="position__country regular">
                  {job.country}
                </p>
              </div>
              <p
                className="position__text regular"
                dangerouslySetInnerHTML={shortenJobOverview(sanitizer(job.overview))}
              ></p>
              <div className="position__link strong-text">
                <Link
                  className="see_more_click"
                  id={`${job.jobId}`}
                  to={{
                    pathname: `/job/${job.jobId}`,
                    state: {
                      jobTitle: job.jobTitle,
                    },
                  }}
                >
                  See More
                </Link>
              </div>
            </div>
          ))}
        </div>
        {state.itemsLeft > 0 && (
          <div className="more-positions__button-container">
            <button
              id="load_more_click"
              className="cta more-positions__load-more"
              onClick={loadMore}
            >
              Load more positions
            </button>
          </div>
        )}
      </div>
    );
  }
};

export default Careers;
