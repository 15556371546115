import React, { useEffect } from 'react';

import EventsHero from './EventsHero';
import EventCard from './EventCard';

import { useEvents } from '../../hooks/events';

const Events = () => {
  const events = useEvents();

  useEffect(() => {
    document.title = "Careers - Events";
  }, []);

  return (
    <div className="events">
      <div className="events__wrapper">
        <EventsHero />
        <div className="events__content">
          {events.map((event, index) => (
            <EventCard
              event={event}
              key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;