import React from "react";
import IcimsLogo from "../../img/icims.png";

const ICIMCard = () =>
    (
        <div className="icim-card">
            <div className="icim-card__body">
                <div className="icim-card__text">
                    <p>
                        You are being redirected to  <img className="icim-card__image" alt="icims logo" width="200" height="200" src={IcimsLogo} /></p>
                    <p>to complete your request. Please  wait.</p>
                </div>
            </div>
            <div className="icim-card__loading">
                <div className="circle circle--0" />
                <div className="circle circle--1" />
                <div className="circle circle--2" />
                <div className="circle circle--3" />
                <div className="circle circle--4" />
            </div>
        </div>
    );


export default ICIMCard;
