import { useEffect } from "react";
import ReactGA from "react-ga";
import history from "../components/history";

const Tracking = (props) => {

  //Initialize Google Analytics ID
  ReactGA.initialize("UA-157980061-1");

  // Sends first hit on page view
  useEffect(() => {
    ReactGA.ga("send", { hitType: "pageview" });

    history.listen(location => {

      /**  
       * Using a setTimeOut of 0 to get this process
       * Out of the js loop execution while
       * DOM title gets updated by router" 
      */
      setTimeout(() => {
        ReactGA.ga("set", {
          title: document.title,
          page: location.pathname + location.search,
          location: window.location.href
        });
        ReactGA.ga("send", { hitType: "pageview" });
      }, 0);
    });
  }, [])

  return null;

}

export default Tracking;
