import React, { Component, useState } from "react";
import ReactDOM from 'react-dom';
import * as wbApi from "../../API/CareersApi";
import getCountries from "../../getCountries";
import HotPositions from "../OpenPositions/HotPositions/HotPositions";
import MorePositions from "../OpenPositions/MorePositions/MorePositions";
import { ENGINEERING_CATEGORY, TECHNOLOGY_CATEGORY } from './../../../utils/helpers';

class CareersSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedCategory: "",
      selectedCity: "",
      jobsLoaded: false,
      jobsError: false,
      cities: [],
      categories: [],
      data: [],
    };
    
  }
 
  async loadJobs() {
    wbApi.get("jobList", "").then((data) => {
      this.setState({
        data,
        jobsLoaded: true
      });
    })
    .catch(error => {
      this.setState({
        jobsError: true
      });
    });
  }

  async getFilters() {
    await wbApi.get("cities", "").then(({ results: cities }) => {
      this.setState({ cities });
    }).catch(function(error) {
    });

    await wbApi.get("categories", "").then(({ results: allCategories }) => {
      const engineeringIndex = allCategories.indexOf(ENGINEERING_CATEGORY);
      let categoriesToShow = [...allCategories];

      if (engineeringIndex !== -1) categoriesToShow.splice(engineeringIndex, 1);

      this.setState({ categories: categoriesToShow });
      this.handleRenderSearchContainer();
    }).catch(function(error) {
    });
  }

  handleChangeOption = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const element = document.querySelector("#hot-positions_scroll_target");
      element.scrollIntoView({ behavior: 'smooth', block: 'start'});
      e.preventDefault();
    }
  };

  filteredPositions() {
    if (this.state.data === undefined) {
      return [];
    }

    const checkCategoryEntry = (entryValue, selectedValue) => {
      const entryValueFormatted = entryValue.toLowerCase();
      const selectedValueFormatted = selectedValue.toLowerCase();

      if (selectedValue === TECHNOLOGY_CATEGORY){
        return entryValueFormatted.includes(ENGINEERING_CATEGORY.toLowerCase()) || entryValueFormatted.includes(selectedValueFormatted)
      } else {
        return entryValueFormatted.includes(selectedValueFormatted);
      }
    };
    const totalData = this.state.data.filter(
      (job) =>
        ![
          [
            [job.country],
            this.state.selectedCity === "All Locations"
              ? ""
              : this.state.selectedCity,
          ],
          [
            [job.jobCategory],
            this.state.selectedCategory === "All Areas"
              ? ""
              : this.state.selectedCategory,
          ],
          [
            [
              job.jobTitle,
              job.overview,
              job.responsabilities,
              job.qualifications,
            ],
            this.state.search,
          ],
        ].some(([entryValues, selectedValue = ""]) => {
          return selectedValue ? !entryValues.some((entryValue) =>
            entryValue ? checkCategoryEntry(entryValue, selectedValue) : false
          ): false;
        })
    );

    return totalData;
  }

  componentDidMount() {
    this.loadJobs();
    this.getFilters();

    getCountries()
      .then((response) => response.json())
      .then((data) => {
        const selectedCountryName = data.country_name;
        if (
          selectedCountryName === "Costa Rica" ||
          selectedCountryName === "Colombia"
        ) {
          this.setState({ selectedCity: selectedCountryName });
        }
      });
    this.cityItems();
  }

  cityItems = () => {
    const cityItems = this.state.cities.map((city) => (
      <React.Fragment key={city}>
        <input onChange={this.handleChangeOption} id={`sel_country${city}`} type="radio" name="selectedCity" value={city} defaultChecked={this.state.selectedCity === city} />
        <label className="selectedCity regular" htmlFor={`sel_country${city}`}>{city}</label><br />
      </React.Fragment>
    ));
    return cityItems;
  };

  categoryItems = () => {
    const categoryItems = this.state.categories.map((category) => (
      <option id={`sel_area_${category}`} value={category} key={category}>{category.replace("_", " ")}</option>
    ));
    return categoryItems;
  };

  render() {
    let filteredJobs = this.filteredPositions();
    let { jobsLoaded } = this.state;
    let { jobsError } = this.state;

    return (
      <>
        <HotPositions data={filteredJobs.slice(0, 3)} dataLoaded={jobsLoaded} dataError={jobsError}/>
        {filteredJobs.length > 3 ? (
          <MorePositions
            data={filteredJobs.slice(3)}
            itemsLeft={filteredJobs.slice(3).length - 6}
          />
        ) : (
          ""
        )}
      </>
    );

  }

  handleRenderSearchContainer = () =>{
    const {selectedCategory} = this.state;
    const searchWrapper =
      <>
        <div className="careers__wrapper careers__search">
          <div className="careers__search-wrapper">
            <form className="careers__search-content">
              <div className="search__container">
                <input
                  className="search__container-item regular"
                  type="search"
                  name="search"
                  placeholder="Search Job"
                  onChange={this.handleChangeOption}
                  onKeyPress={this.handleKeyPress}
                />
              </div>
              <div className="careers__selector">
                <div className="careers__selector-location">
                  <label htmlFor="selectedCity" className="careers__selector-title">Location:</label><br />
                    <div className="locationTitle">{this.cityItems()}</div>
                </div>
                <div className="careers__selector-job">
                  <label htmlFor="selectedCategory" className="careers__selector-title">Job Sector:</label>
                  <select
                    name="selectedCategory"
                    id="selectedCategory"
                    className={`careers__selector-item regular sel_area_${selectedCategory}`}
                    onChange={this.handleChangeOption}
                    value={this.state.category}
                  >
                    <option key="" value="All Areas">All Areas</option>
                    {this.categoryItems()} 
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>;
    return ReactDOM.render(searchWrapper, document.getElementById('hero_search_wrapped'));
  }

}

export default CareersSearch;
