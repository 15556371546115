import React, { useEffect } from "react";
import ThanksInfo from "./ThanksInfo/ThanksInfo";

const ThankYouPage = () => {

  useEffect(() => {
    document.title = 'Careers - Thank You Page';
  }, []);

  return(
    <div className="thanks">
      <div className="thanks__wrapper">
        <ThanksInfo />       
      </div>
    </div>
  )
}

export default ThankYouPage;
