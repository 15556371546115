import React from "react";
import PropTypes from "prop-types";

const ImgSlide = ({imgSrc, imgAlt= 'carrousel image', children = null}) => {
    return (
            <div className="img-slide-container">
                <img
                src={imgSrc}
                alt={imgAlt} />
                    {children}
            </div>
    );
}

ImgSlide.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string,
    children: PropTypes.object
}

export default ImgSlide;