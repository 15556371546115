import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import HomeVideo from "../HomeVideo/HomeVideo";
import ImgSlide from "./ImgSlide/ImgSlide";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return windowWidth;
};

const MEDIUM_WIDTH = 641;
const slides = [{
  alt: "real creative solutions",
  cta: {
    target: "_self",
    text: "Apply now",
    url: "https://www.linkedin.com/company/pgd/jobs",
    target: "_blank",
    idClick: "apply_now_home"
  },
  copy: "Our talent drives the success of the solutions beyond boundaries.",
  elDesktop: require("../../../img/carousel_homepage/bg-pdg-ganger-games-principal-banner-2022-d.png"),
  elMobile: require("../../../img/carousel_homepage/bg-pdg-ganger-games-principal-banner-2022-m.png"),
  h2: "CONNECTED THROUGH IDEAS",
  id: 0,
  textPosition: 'right',
  type: "img",
}, {
  alt: "real creative solutions",
  cta: {
    target: "_blank",
    text: "View event",
    url: "https://uplevel.byprodigious.com/",
  },
  elDesktop: require("../../../img/carousel_homepage/bg-digital-uplevel-submit-d.png"),
  elMobile: require("../../../img/carousel_homepage/bg-digital-uplevel-submit-m.png"),
  id: 1,
  special: "digital-uplevel-summit",
  textPosition: 'center',
  type: "img",
}, {
  alt: "real creative solutions",
  cta: {
    target: "_blank",
    text: "View event",
    url: "https://uplevel.byprodigious.com/podcasts/",
  },
  elDesktop: require("../../../img/carousel_homepage/bg-uplevel-talks-d.png"),
  elMobile: require("../../../img/carousel_homepage/bg-uplevel-talks-m.png"),
  id: 2,
  special: "uplevel-talks",
  textPosition: 'left',
  type: "img",
}];

const HomeCarousel = () => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplaySpeed: 10000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="home-carousel">
      <Slider {...settings}>
        {slides.map((slide) => {
          const elSrc =
            useWindowWidth() < MEDIUM_WIDTH ? slide.elMobile : slide.elDesktop;

          return slide.type === "img" ? (
            <ImgSlide key={slide.id} imgSrc={elSrc} imgAlt={slide.alt}>
              <div className={`carousel-img text-position-${slide.textPosition} ${slide.special ? `carousel-img__${slide.special}` : '' }`}>
                {slide.h2 && (
                  <h2>{slide.h2}</h2>
                )}
                {slide.needDivider && (
                  <div className="carousel-img__line-divider--mobile"></div>
                )}
                {slide.h3 && (
                  <h3>{slide.h3}</h3>
                )}
                {slide.copy && (
                  <p>{slide.copy}</p>
                )}
                {slide.cta && (
                  slide.cta.target === '_blank' ?
                  <a
                    className="round-cta"
                    href={slide.cta.url}
                    rel="noopener noreferrer"
                    target={slide.cta.target}>
                      {slide.cta.text}
                  </a> :
                  <Link
                    id={`${slide.cta.idClick ? slide.cta.idClick : ''}`}
                    className="round-cta"
                    to={slide.cta.url}>
                      {slide.cta.text}
                  </Link>
                )}
              </div>
            </ImgSlide>
          ) : (
            <div key={slide.id}>
              <HomeVideo videoSrc={elSrc} h2={slide.h2} />
              <div className="carousel-video">
                <h2>{slide.h2}</h2>
                <p>{slide.copy}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomeCarousel;
