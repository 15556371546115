import React from "react";
import "../fonts.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const logoStr = "logo";

  return (
    <footer className="footer">
      <div className="footer__wrapper dotted-bg">
        <div className="footer__logowrapper">
          <div className="footer__logo">
            <Link to="/" id="foot home_logo/">
              <div>
                <img
                  src={require(`../../img/${logoStr}-publicis-groupe.png`)}
                  className="global-delivery"
                  alt={`Publicis Global Delivery ${logoStr}`}
                ></img>
              </div>
            </Link>
          </div>
          <div className="footer__navigation">
            <Link id="foothome_carreers" 
                className="footer__navigation-item" 
                to={{ pathname: "https://www.linkedin.com/company/pgd/jobs" }} 
                target="_blank">
              Careers
            </Link>
            <span className="footer__navigation-separator" >&nbsp;</span>
            <Link id="foothome_events" className="footer__navigation-item" to="/events">
              Events
            </Link>
            <span className="footer__navigation-separator" >&nbsp;</span>
            <Link id="foothome_about_us" className="footer__navigation-item" to="/about-us">
              About&nbsp;Us
            </Link>
            <span className="footer__navigation-separator" >&nbsp;</span>
            <Link id="foothome_contact" className="footer__navigation-item" to="/contact-us">
              Contact
            </Link>
          </div>
        </div>
        <div className="country__wrapper">
          <div className="country__content">
            <div className="country__content-costarica">
              <p className="country-name">Publicis Global Delivery</p>
              <a
                className="country-links regular"
                href="mailto:pgdlatamjobs@publicisgroupe.com"
                aria-label="Costa Rica contact us email"
              >
                pgdlatamjobs@publicisgroupe.com
              </a>
            </div>
          </div>
          <div className="social__icons">
            <a
              id="foothome_fb"
              className="social__icons-item"
              href="https://www.facebook.com/PGDLatam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../img/fb_icon.png")}
                alt="facebook icon"
              ></img>
            </a>
            <a
              id="foothome_linkedin"
              className="social__icons-item"
              href="https://www.linkedin.com/company/pgd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../img/in_icon.png")}
                alt="linkedin icon"
              ></img>
            </a>
            <a
              id="foothome_ig"
              className="social__icons-item"
              href="https://instagram.com/pgd_latam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../img/ig_icon.png")}
                alt="instagram icon"
              ></img>
            </a>
            <a
              className="social__icons-item"
              href="https://www.youtube.com/c/publicisglobaldeliverylatam/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../img/youtube-icon.svg")}
                alt="Youtube icon"
              ></img>
            </a>
          </div>
        </div>
        <hr className="footer__line"></hr>
        <p className="footer__copyright regular">
          Copyright © {new Date().getFullYear()} Publicis Global Delivery. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
