import React, { useEffect } from "react";
import ContactForm from "./Form/ContactForm";

const Contact = () => {
  useEffect(() => {
    document.title = "Careers - Contact Us";
  }, []);

  return (
    <React.Fragment>
      <div id="contact-us" name="contact-us" className="contact-section">
        <div className="contact-section__container">
          <div className="contact-section__container--title-container">
            <div className="contact_title contact-section__container--title strong-text">
              <h2 className="black_title">LET'S MAKE</h2>
              <h2 className="subtitle regular">IDEAS HAPPEN </h2>
            </div>
            <div className="contact-section__container--copy regular">
              <p>
                We are empowering world transformation and business success.
              </p>
            </div>
          </div>
          <div className="contact-section__container--form">
            <ContactForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
