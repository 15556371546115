import React from "react";
import PageHero from "../../PageHero";

const imagesList = {
  desktop: require("../../../img/events/ph-events-d.png"),
  mobile: require("../../../img/events/ph-events-m.png")
};
const EventsHero = () => (
  <PageHero
    bold="OF OUR EVOLUTION"
    cta={{
      copy: "Apply now",
      url: "/open-positions"
    }}
    divClass="events__hero"
    imgAltText="Man in front of computer monitor."
    imagesList={imagesList}
    subtitle="Find all about our latest events."
    title="BE PART"
  />
)

export default EventsHero;
