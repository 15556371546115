import React, { Component } from "react";
import Site from "./Site/Site";

require('es6-promise').polyfill();
require('isomorphic-fetch');

class App extends Component {
  render() {
    return (
      <Site />
    );
  }
}

export default App;
