/**
 * Check if  a given text has json format and can be parsed
 * @param {string} str - text to be checked
 * */
export const isJson = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

export const shortenJobOverview = (htmlText) => {
  let introText = decodeURIComponent(htmlText)

  if (introText.length > 160) {
    return {
      __html: introText.replace(/<\/?[^>]+>/gi, '').slice(0, 160) + '...',
    }
  } else {
    return {
      __html: introText,
    }
  }
}

export const ENGINEERING_CATEGORY = "Engineering";
export const TECHNOLOGY_CATEGORY = "Technology";
export const categoryNameFormatted = (categoryName) => {
  let nameFormatted = categoryName;

  if (categoryName === ENGINEERING_CATEGORY) {
    nameFormatted = TECHNOLOGY_CATEGORY;
  }

  return nameFormatted.replace("_", " ");
};
