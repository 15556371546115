import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { throttle } from "throttle-debounce";

function Nav() {
  const [hiddenNav, setHiddenNav] = useState(false);
  let [activeMenuPage, setActiveMenuPage] = useState("");
  const location = useLocation();
  const prevScroll = useRef(0);
  const hamburgerRef = useRef();
  const navContainerRef = useRef();

  const homePath = "/";
  const locationStyleSuffix =
    location.pathname === homePath ? "__home" : "__default";
  const logoSrc =
    location.pathname === homePath ? "logo_home.png" : "logo_publicis_groupe.png";
  const mobileMenuSrc =
    location.pathname === homePath ? "mobile_menu_home.png" : "mobile_menu.png";
  const toggleNavBar = hiddenNav ? "-hidden" : "-show";
  const desktopMenuItemStyle = `desktop__menu-item desktop__menu-item${locationStyleSuffix}`;
  const desktopMenuLineStyle = `desktop__menu-line desktop__menu-line${locationStyleSuffix}`;

  const onFadeIn = () => {
    hamburgerRef.current.style.display = "block";
    navContainerRef.current.style.display = "none";
  };
  const onFadeOut = () => {
    hamburgerRef.current.style.display = "none";
    navContainerRef.current.style.display = "flex";
  };

  const addNavPlaceholder = () => {
    const { paddingTop } = document.body.style;

    if (!paddingTop || paddingTop === "0px") {
      const headerNav = document.querySelector(".navigation__wrapper");
      const navHeight = `${headerNav.clientHeight}px`;
      document.body.style.paddingTop = navHeight;
    }
  };

  const removeNavPlaceholder = () => {
    const { paddingTop } = document.body.style;

    if (paddingTop || paddingTop !== "0px") {
      document.body.style.paddingTop = "";
    }
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setActiveMenuPage(location.pathname.split("/").pop());

    const throttledScroll = throttle(100, handleScroll);

    if (locationStyleSuffix === "__default") {
      addNavPlaceholder();
    } else {
      removeNavPlaceholder();
    }

    window.addEventListener("scroll", throttledScroll);
    return () => window.removeEventListener("scroll", throttledScroll);
  }, [locationStyleSuffix, location]);

  const handleScroll = () => {
    const { scrollY } = window;
    const shouldHide = prevScroll.current < scrollY;
    prevScroll.current = scrollY < 0 ? prevScroll.current = 0 : prevScroll.current = scrollY;
    setHiddenNav(shouldHide);
  };

  return (
    <nav
      className={`navigation navigation${locationStyleSuffix} navigation${toggleNavBar}`}
    >
      <section
        className={`navigation__wrapper navigation__wrapper${locationStyleSuffix}`}
      >
        <div className="navigation__container medium" ref={navContainerRef}>
          <div className="navigation__logo">
            <Link to="/" className="navigation__logo-link" id="nav home_logo">
              <img
                className="navigation__logo-image"
                src={require(`../../img/${logoSrc}`)}
                alt="Prodigious Logo"
              />
            </Link>
          </div>
          <div className="navigation__wrapper-mobile" onClick={onFadeIn}>
            <img
              className="navigation__mobile-image"
              src={require(`../../img/${mobileMenuSrc}`)}
              alt="Mobile Menu"
            />
          </div>
          <div className="navigation__wrapper-desktop">
            <ul className={`desktop__menu desktop__menu${locationStyleSuffix}`}>
              <li className={desktopMenuItemStyle}>
                <Link
                  id="navhome_carreers"
                  className={`${activeMenuPage === "open-positions"
                      ? "active-page active__open-positions"
                      : ""
                    }`}
                    to={{ pathname: "https://www.linkedin.com/company/pgd/jobs" }} 
                    target="_blank"
                >
                  Careers
                </Link>
              </li>
              <li className={desktopMenuLineStyle}></li>
              <li className={desktopMenuItemStyle}>
                <Link
                  id="navhome_events"
                  className={`${activeMenuPage === "events"
                    ? "active-page active__events"
                    : ""
                    }`}
                  to="/events"
                >
                  Events
                </Link>
              </li>
              <li className={desktopMenuLineStyle}></li>
              <li className={desktopMenuItemStyle}>
                <Link
                  id="navhome_about_us"
                  className={`${activeMenuPage === "about-us" ? "active-page active__about-us" : ""
                    }`}
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li className={desktopMenuLineStyle}></li>
              <li className={desktopMenuItemStyle}>
                <Link
                  id="navhome_contact"
                  className={`${activeMenuPage === "contact-us" ? "active-page active__contact-us" : ""
                    }`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="hamburger__wrapper" ref={hamburgerRef}>
          <div className="hamburger__header">
            <div className="hamburger__logo">
              <img
                className="hamburger__logo-image"
                src={require("../../img/logo_publicis_groupe.png")}
                alt="Mobile Menu Logo"
              />
            </div>
            <div className="hamburger__close" onClick={onFadeOut}>
              <img
                className="hamburger__close-image"
                src={require("../../img/mobile_menu_close.png")}
                alt="Mobile Menu Close"
              />
            </div>
          </div>
          <div className="hamburger__menu">
            <Link
              id="navhome_carreers"
              className="hamburger__menu-item hamburger__menu-link"
              to={{ pathname: "https://www.linkedin.com/company/pgd/jobs" }} 
              target="_blank"
            >
              Careers
            </Link>
            <hr className="hamburger__menu-item hamburger__menu-line" />
            <Link
              id="navhome_events"
              className="hamburger__menu-item hamburger__menu-link"
              to="/events"
              onClick={onFadeOut}
            >
              Events
            </Link>
            <hr className="hamburger__menu-item hamburger__menu-line" />
            <Link
              id="navhome_about_us"
              className="hamburger__menu-item hamburger__menu-link"
              to="/about-us"
              onClick={onFadeOut}
            >
              About Us
            </Link>
            <hr className="hamburger__menu-item hamburger__menu-line" />
            <Link
              id="navhome_contact"
              className="hamburger__menu-item hamburger__menu-link"
              to="/contact-us"
              onClick={onFadeOut}
            >
              Contact
            </Link>
          </div>
        </div>
      </section>
    </nav>
  );
}
export default Nav;
