import React, { useEffect } from "react";
import HomeCarousel from "./HomeCarousel/HomeCarousel";
import HomeContent from "./HomeContent/HomeContent";

const Home = () => {
  useEffect(() => {
    document.title = "Careers";
  }, []);

  return (
    <div className="home__page">
      <HomeCarousel />
      <HomeContent />
    </div>
  );
};

export default Home;
