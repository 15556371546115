import React, { Component } from "react";
import videojs from "video.js";

export default class HeroVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true,
      loop: true,
    };
  }

  componentDidMount() {
    // instantiate video.js
    this.player = videojs(
      this.videoNode,
      this.props,
      function onPlayerReady() {}
    );
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className="home-video">
        <div data-vjs-player>
          <video
            className="video-js mx"
            preload="auto"
            data-setup='{"fluid": true}'
            ref={(node) => (this.videoNode = node)}
            src={this.props.videoSrc}
            autoPlay={this.state.autoplay}
            loop={this.state.loop}
            muted
            playsInline
          />
        </div>
      </div>
    );
  }
}
