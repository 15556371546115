import React from "react";

const ThanksInfo = () => (
  <div className="thanks__masthead">
    <div className="thanks__masthead-container">
      <div className="thanks__title">
       AWESOME!
      </div>
      <div className="masthead__subtitle thanks__subtitle regular">
        <h2 className="thanks__subtitle-text">Applying is the first step to become a Magic Maker.</h2> 
        <h2 className="thanks__subtitle-text">Our Talent Acquisition team will contact you soon.</h2>       
      </div>      
    </div>
    <div
      className="thanks__masthead-bg"
      role="img">        
      </div>
  </div>
);

export default ThanksInfo;
